import { quickLinks, downloadApp } from "../../components/Constants";

export const StepsObj = {
    navSeq: quickLinks[0].name,
    buyerStep1: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-buyer-step1.svg",
    buyerStep2: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-buyer-step2.svg",
    buyerStep3: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-buyer-step3.svg",
    buyerStep4: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-buyer-step4.svg",
    sellerStep1: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-buyer-step1.svg",
    sellerStep2: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-seller-step2.svg",
    sellerStep3: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-seller-step3.svg",
    sellerStep4: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/steps-seller-step4.svg",
    mobBuyerS1: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/buyers-step-1.svg",
    mobBuyerS2: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/buyers-step-2.svg",
    mobBuyerS3: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/buyers-step-3.svg",
    mobBuyerS4: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/buyers-step-4.svg",
    mobSellerS1: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/seller-step-1.svg",
    mobSellerS2: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/seller-step-2.svg",
    mobSellerS3: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/seller-step-3.svg",
    mobSellerS4: "https://abazaar.s3-ap-south-1.amazonaws.com/images/quickLinks/seller-step-4.svg"
};
//press release
export const PressAndMediaObj = {
    navSeq: quickLinks[1].name,
    cardsList: [
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-1.png",
            imgAlt: "Agribazaar-Triple A Awards 2020",
            href: "https://blog.agribazaar.com/agriazaar-wins-triple-a-awards-2020-the-asset/",
            title: "Agribazaar wins Triple A Awards 2020: The Asset",
            date: "DECEMBER 28, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-2.png",
            imgAlt: "Foray into agri-input segment",
            href: "https://blog.agribazaar.com/agribazaar-makes-foray-into-agri-input-segment/",
            title: "Agribazaar makes foray into agri-input segment",
            date: "AUGUST 25, 2020",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-3.png",
            imgAlt: "Treble headcount to 500, double footprint",
            href: "https://blog.agribazaar.com/agribazaar-to-treble-headcount-to-500-double-footprint/",
            title: "Agribazaar to treble headcount to 500, double footprint",
            date: "AUGUST 17, 2020",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-4.jpg",
            imgAlt: "Spike in oil demand",
            href: "https://blog.agribazaar.com/mustard-seed-up-26-in-four-months-on-short-supply-spike-in-oil-demand/",
            title: "Mustard seed up 26% in four months on short supply, spike in oil demand",
            date: "JULY 28, 2020",
            duration: "5 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-5.png",
            imgAlt: "Expand quickly",
            href: "https://blog.agribazaar.com/indias-agribazaar-looks-to-expand-quickly-as-government-eases-trading-norms-for-farmers/",
            title: "India’s Agribazaar looks to expand quickly as government eases trading norms for farmers",
            date: "JUNE 15, 2020",
            duration: "4 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-6.png",
            imgAlt: "AgriBazaar’s digital e-mandi helps small farmers get best price for their produce",
            href: "https://blog.agribazaar.com/agribazaars-digital-e-mandi-helps-small-farmers-get-best-price-for-their-produce/",
            title: "AgriBazaar’s digital e-mandi helps small farmers get best price for their produce",
            date: "MAY 2, 2020",
            duration: "6 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-7.jpg",
            imgAlt: "Securing farmers with technology",
            href: "https://blog.agribazaar.com/securing-farmers-with-technology-how-agritech-can-be-a-solution-to-farmers-produce-related-woes/",
            title: "Securing farmers with technology: How Agritech can be a solution to farmers’ produce-related woes",
            date: "APRIL 30, 2020",
            duration: "7 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-8.png",
            imgAlt: "Agri-tech",
            href: "https://blog.agribazaar.com/agri-tech-harvesting-benefits-of-tech-at-the-mandi/",
            title: "Agri-tech: Harvesting benefits of tech at the mandi",
            date: "APRIL 23, 2020",
            duration: "6 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-9.png",
            imgAlt: "Fixing food supply chain with e-mandi",
            href: "https://blog.agribazaar.com/coronavirus-this-agritech-startup-is-fixing-food-supply-chain-with-e-mandis/",
            title: "Coronavirus: This agritech startup is fixing food supply chain with e-mandis",
            date: "APRIL 22, 2020",
            duration: "9 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-10.jpg",
            imgAlt: "Coronavirus lockdown | agri supply improved considerably: AgriBazaar",
            href: "https://blog.agribazaar.com/coronavirus-lockdown-no-scarcity-of-food-agri-supply-improved-considerably-agribazaar/",
            title: "Coronavirus lockdown | No scarcity of food, agri supply improved considerably: AgriBazaar",
            date: "APRIL 20, 2020",
            duration: "5 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-11.jpg",
            imgAlt: "Provide Market Linkages to Smallholder Farmers",
            href: "https://blog.agribazaar.com/bayer-partners-with-agribazaar-to-provide-market-linkages-to-smallholder-farmers/",
            title: "Bayer Partners with Agribazaar to Provide Market Linkages to Smallholder Farmers",
            date: "MARCH 6, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-12.jpg",
            imgAlt: "Farmers’ Access to Big Data",
            href: "https://blog.agribazaar.com/democratizing-farmers-access-to-big-data/",
            title: "Democratizing Farmers’ Access to Big Data",
            date: "MARCH 4, 2020",
            duration: "5 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-13.jpg",
            imgAlt: "Synergy Media Specialists",
            href: "https://blog.agribazaar.com/agri-talks-with-agribazaar-synergy-media-specialists/",
            title: "Agri Talks with agribazaar: Synergy Media Specialists",
            date: "AUGUST 27, 2019",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-14.png",
            imgAlt: "India’s Alibaba of agriculture",
            href: "https://blog.agribazaar.com/agribazaar-indias-alibaba-of-agriculture/",
            title: "Agribazaar: India’s Alibaba of agriculture",
            date: "AUGUST 15, 2019",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-15.png",
            imgAlt: "In Conversation with ThinkAg member",
            href: "https://blog.agribazaar.com/in-conversation-with-thinkag-member-agribazaar/",
            title: "In Conversation with ThinkAg member Agribazaar",
            date: "JULY 4, 2019",
            duration: "12 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-16.png",
            imgAlt: "The Asset: Triple A Awards 2019",
            href: "https://blog.agribazaar.com/the-asset-triple-a-treasury-trade-supply-chain-and-risk-management-awards-2019/",
            title: "Agribazaar wins Triple A Awards 2019: The Asset",
            date: "MAY 8, 2019",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-17.png",
            imgAlt: "Agribazaar to revolutionize the industry",
            href: "https://blog.agribazaar.com/nuts-and-dried-fruit-trading-2-0-agribazaar-to-revolutionize-the-industry/",
            title: "Nuts and Dried Fruit Trading 2.0: Agribazaar to revolutionize the industry",
            date: "MAY 9, 2018",
            duration: "12 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-18.png",
            imgAlt: "Associate Partner for Agro Summit – 2017",
            href: "https://blog.agribazaar.com/the-economic-times-agro-summit-2017/",
            title: "Agribazaar becomes an Associate Partner for Agro Summit – 2017: The Economic Times",
            date: "DECEMBER 20, 2017",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsPressRelease/PRRL-19.png",
            imgAlt: "Nafed to launch e-auction platform for pulses trade",
            href: "https://blog.agribazaar.com/now-nafed-to-launch-e-auction-platform-for-pulses-trade/",
            title: "Now, Nafed to launch e-auction platform for pulses trade",
            date: "AUGUST 4, 2017",
            duration: "4 MIN READ"
        }
    ]
};
export const FAQsObj = {
    navSeq: quickLinks[2].name,
    whatIsAgribazaarStr: "It is an online market place for buying and selling of agri commodities. Participants can list their requirement through creating auctions or classifieds. Participants can also view existing offers and make their own offers. The trade settlement is backed by financial security from the buyer and stocks of the seller.",
    registrationTL1: "To register, call us on +91 90903 97777 or write to us on customersupport@agribazaar.com with your firm name, address, email address and your phone number and we will reach out to you.",
    registrationTL2: "Not at all. Registration on agribazaar is absolutely free.",
    registrationTL3: "We require basic KYC documents (Aadhar Card, PAN card address proof, taxation details and bank account details) of yours and your firm for the registration.",
    registrationTR1: "agribazaar provides unique account numbers to each client for them to manage their funds (like demat accounts) including withdrawal at any point of time. We need your account details to remit the money to your bank account.",
    registrationTR2: "The user needs to fill an online form provided by agribazaar if they want to get themselves registered in NAFED. There are some documents needed to be uploaded for verification process along with the form which includes PAN, canceled cheque, Authorization letter, Partnership deed/MOA, annual return, balance sheet, Certificate of GST, TAT number, payment details, List of Directors/Partners, and FSSAI License copy. All these documents are necessary to be uploaded for the approval of registration.",
    tradeTL1: "After you login with your mobile number and password you can go to trade screen and create an order under buying tab. You can select your requirement like quantity, quality, location price and Start date and end date of the trade.",
    tradeTL2: "After you login with your mobile number and password you can go to trade screen and create an order under selling tab. You can select the quantity of your commodity, quality, location, price and duration of the trade.",
    tradeTL3: "Select any sell order from the bazaar screen and then select to participate in the same. If the order is live (For eg: if auction is running) then you can submit your bid after paying the order margin. Depending upon the order type, you may emerge as a winner.",
    tradeTL4: "agribazaar provides testing and certification facility through its empanelled collateral managers. Sellers can choose to certify their produce by calling us on our helpline and we will coordinate the same. In case, there is a dispute on tested quality, agribazaar guarantees you a complete protection from quality and quantity deductions at the time of lifting.",
    tradeTL5: "You personally upload and manage your own trade. Negotiate price and quality, counteroffer, reject or accept the offer.",
    tradeTL6: [
        "For Winner – Once the trade is completed or the stock is delivered, EMD is refunded in the virtual agribazaar account.",
        "For Losing entity – Once the auction ends, the EMD is refunded within 10 to 15 mins."
    ],
    tradeTL7: "Search for the commodity you want to bid against and you’ll be redirected to the auction page where you can find the Ongoing auctions (both Buying and Selling) to participate in, for the same commodity. After that, you will have to pay the mentioned EMD to place your bid in that particular auction. Once you accept the ‘Terms and Conditions’ and the EMD has been paid successfully, you would be eligible to place a bid in the auction.",
    tradeTR1: "Select any buy order from the bazaar screen and then select to participate in the same. As a seller, you have to select one of the stock listing to participate in the auction. Depending upon the order type, you may emerge as a winner.",
    tradeTR2: "At agribazaar, anonymity is your right and the platform will not reveal any participant’s name till the time trade is confirmed.",
    tradeTR3: "Buyer can specify their desired quality norms with rejection thresholds. Within the acceptable quality range, agribazaar has a set of pre-defined deduction rules which will be applied at the time of settlement.",
    tradeTR4: "All deliveries will take place in presence of agribazaar’s personnel. If there is any kind of dispute, then agribazaar personnel will collect 2 samples of disputed commodity which will be submitted to an independent assayer and the final settlement will done on the basis of the results.",
    tradeTR5: "EMD is earnest money deposit. You need to have the EMD amount in your virtual agribazaar account at least 2 hrs before the auction starts. You can transfer funds into your virtual account by RTGS or NEFT.",
    tradeTR6: "Log in to your agribazaar account and find ‘Trade’ to begin the procedure. Select the kind of auction you want to create, i.e buying, selling or order. Enter all the necessary details like commodity, quantity, delivery point and continue the process by setting your required parameters. After this, you have to pay an order margin of 10% to make your auction live. Once you accept the ‘Terms and Conditions’, your auction is live. You can also view your Ongoing auction or any Upcoming auction in ‘Manage Auctions’ section.",
    paymentsTL1: "It is mandatory for buyer to submit 100% of the estimated trade value with agribazaar settlement account before lifting the commodity. Hence, the seller is completely secure about receiving the payments.",
    paymentsTL2: "You can find Ledger and request to withdraw your money from agribazaar account by clicking on Withdraw button.",
    paymentsTL3: "The seller is completely secure about receiving the payments because the buyer is depositing the entire amount to the agribazaar settlement account before picking up any commodity. Payment gateways are SSL and STQC certified.",
    paymentsTR1: "As soon as the commodity delivery is acknowledged by the buyer and agribazaar personnel (in any case less than 10 days), the payment will be transferred to the seller’s agribazaar account. You can can choose to withdraw that money to your bank account at any point of time.",
    paymentsTR2: "You can find ‘Ledger’ once you Login into your account in which funds can be deposited. The ledger is similar to a passbook that will give all the details of funds coming in and going out.",
    paymentsTR3: "agribazaar virtual account acts as an e-wallet provided by agribazaar in which the user first add funds from his local bank account to process further transaction. The wallet is a low fee account in which the user can add any amount with no additional cost.",
};
export const TermsOfUseObj = {
    navSeq: quickLinks[3].name,
    googleplayLink: downloadApp[0].route,
    playStoreLink: downloadApp[1].route

};