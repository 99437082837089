import { agriInsights } from "../../components/Constants";

export const blogObj = {
    navSeq: agriInsights[0].name,
    cardsList: [
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-1.jpg",
            imgAlt: "Online Auction Engine by agribazaar",
            href: "https://blog.agribazaar.com/online-auction-engine-by-agribazaar/",
            title: "Online Auction Engine by agribazaar",
            date: "MARCH 31, 2022",
            duration: "3 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-2.jpg",
            imgAlt: "Impact of IT Innovation for Growing Indian Agriculture",
            href: "https://blog.agribazaar.com/impact-of-it-innovation-for-growing-indian-agriculture/",
            title: "Impact of IT Innovation for Growing Indian Agriculture",
            date: "MARCH 21, 2022",
            duration: "4 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-3.jpg",
            imgAlt: "Principles of Regenerative Agriculture",
            href: "https://blog.agribazaar.com/principles-of-regenerative-agriculture/",
            title: "Principles of Regenerative Agriculture",
            date: "MARCH 10, 2022",
            duration: "4 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-4.jpg",
            imgAlt: "Renewable Energy: A Boon to Indian Farmers",
            href: "https://blog.agribazaar.com/renewable-energy-a-boon-to-indian-farmers/",
            title: "Renewable Energy: A Boon to Indian Farmers",
            date: "FEBRUARY 24, 2022",
            duration: "3 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-5.jpg",
            imgAlt: "AgriKnow: Crop & Farm Management",
            href: "https://blog.agribazaar.com/agriknow-crop-farm-management/",
            title: "AgriKnow: Crop & Farm Management",
            date: "FEBRUARY 16, 2022",
            duration: "3 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-6.jpg",
            imgAlt: "Organic Farming Measures by Indian Government",
            href: "https://blog.agribazaar.com/organic-farming-measures-by-indian-government/",
            title: "Organic Farming Measures by Indian Government",
            date: "JANUARY 28, 2022",
            duration: "4 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-7.jpg",
            imgAlt: "Organic Farming in India: Facts and Way Forward",
            href: "https://blog.agribazaar.com/organic-farming-in-india-facts-and-way-forward/",
            title: "Organic Farming in India: Facts and Way Forward",
            date: "JANUARY 20, 2022",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-8.jpg",
            imgAlt: "Rabi season in India: 2021-22",
            href: "https://blog.agribazaar.com/rabi-season-in-india-2021-22/",
            title: "Rabi season in India: 2021-22",
            date: "JANUARY 7, 2022",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-9.jpg",
            imgAlt: "First Private Online Mandi in Madhya Pradesh by agribazaar",
            href: "https://blog.agribazaar.com/first-private-online-mandi-in-madhya-pradesh-by-agribazaar/",
            title: "First Private Online Mandi in Madhya Pradesh by agribazaar",
            date: "DECEMBER 23, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsBlog/BLG-10.jpg",
            imgAlt: "Current Initiatives and Future of Digital Agriculture in India",
            href: "https://blog.agribazaar.com/current-initiatives-and-future-of-digital-agriculture-in-india/",
            title: "Current Initiatives and Future of Digital Agriculture in India",
            date: "DECEMBER 17, 2021",
            duration: "4 MIN READ"
        }
    ]
};
//newsletter
export const agriTalkMagazineObj = {
    navSeq: agriInsights[1].name,
    cardsList: [
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-1.jpg",
            imgAlt: "agritalk-january-2022",
            href: "https://blog.agribazaar.com/agritalk-january-2022/",
            title: "agriTALK: January 2022",
            date: "FEBRUARY 7, 2022",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-2.jpg",
            imgAlt: "agritalk-december-2021",
            href: "https://blog.agribazaar.com/agritalk-december-2021/",
            title: "agriTALK: December 2021",
            date: "JANUARY 3, 2022",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-3.png",
            imgAlt: "agritalk-november-2021",
            href: "https://blog.agribazaar.com/agritalk-november-2021/",
            title: "agriTALK: November 2021",
            date: "NOVEMBER 15, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-4.png",
            imgAlt: "agritalk-october-2021",
            href: "https://blog.agribazaar.com/agritalk-october-2021/",
            title: "agriTALK: October 2021",
            date: "OCTOBER 20, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-5.png",
            imgAlt: "agritalk-september-2021",
            href: "https://blog.agribazaar.com/agritalk-september-2021/",
            title: "agriTALK: September 2021",
            date: "SEPTEMBER 6, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-6.png",
            imgAlt: "agritalk-july-2021",
            href: "https://blog.agribazaar.com/agritalk-july-2021/",
            title: "agriTALK: July 2021",
            date: "JULY 19, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-7.png",
            imgAlt: "agritalk-june-2021",
            href: "https://blog.agribazaar.com/agritalk-june-2021/",
            title: "agriTALK: June 2021",
            date: "JUNE 19, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-8.png",
            imgAlt: "agritalk-may-2021",
            href: "https://blog.agribazaar.com/agritalk-may-2021/",
            title: "agriTALK: May 2021",
            date: "MAY 31, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-9.png",
            imgAlt: "agritalk-april-2021",
            href: "https://blog.agribazaar.com/agritalk-april-2021/",
            title: "agriTALK: April 2021",
            date: "APRIL 13, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsNewsLetter/NL-10.png",
            imgAlt: "agritalk-march-2021",
            href: "https://blog.agribazaar.com/agritalk-march-2021/",
            title: "agriTALK: March 2021",
            date: "MARCH 10, 2021",
            duration: "1 MIN READ"
        }
    ]
};
//commodity outlook
export const agriInsightsObj = {
    navSeq: agriInsights[2].name,
    cardsList: [
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-1.png",
            imgAlt: "Soybean",
            href: "https://blog.agribazaar.com/commodity-outlook-soybean-8/",
            title: "Commodity Outlook – Soybean",
            date: "NOVEMBER 30, 2021",
            duration: "3 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-2.jpg",
            imgAlt: "Groundnut",
            href: "https://blog.agribazaar.com/commodity-outlook-groundnut-9/",
            title: "Commodity Outlook – Groundnut",
            date: "NOVEMBER 22, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-3.jpg",
            imgAlt: "Tur (Red Gram / Cajanus Cajan)",
            href: "https://blog.agribazaar.com/commodity-outlook-tur-red-gram-cajanus-cajan-3/",
            title: "Commodity Outlook – Tur (Red Gram / Cajanus Cajan)",
            date: "NOVEMBER 15, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-4.jpg",
            imgAlt: "Bajra (Pearl Millet)",
            href: "https://blog.agribazaar.com/commodity-outlook-bajra-pearl-millet/",
            title: "Commodity Outlook – Bajra (Pearl Millet)",
            date: "NOVEMBER 8, 2021",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-5.png",
            imgAlt: "Bengal Gram (Chana)",
            href: "https://blog.agribazaar.com/commodity-outlook-bengal-gram-chana-20/",
            title: "Commodity Outlook – Bengal Gram (Chana)",
            date: "NOVEMBER 1, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-6.png",
            imgAlt: "Mustard Seed",
            href: "https://blog.agribazaar.com/commodity-outlook-mustard-seed-8/",
            title: "Commodity Outlook – Mustard Seed",
            date: "OCTOBER 25, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-7.png",
            imgAlt: "Castor seed",
            href: "https://blog.agribazaar.com/commodity-outlook-castor-seed-18/",
            title: "Commodity Outlook – Castor Seed",
            date: "OCTOBER 20, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-8.jpg",
            imgAlt: "Wheat",
            href: "https://blog.agribazaar.com/commodity-outlook-wheat-18/",
            title: "Commodity Outlook – Wheat",
            date: "OCTOBER 11, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-9.jpg",
            imgAlt: "Cotton",
            href: "https://blog.agribazaar.com/commodity-outlook-cotton-3/",
            title: "Commodity Outlook – Cotton",
            date: "OCTOBER 5, 2021",
            duration: "2 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsightsCommodityOutlook/CO-10.png",
            imgAlt: "Sugar",
            href: "https://blog.agribazaar.com/commodity-outlook-sugar-4/",
            title: "Commodity Outlook – Sugar",
            date: "SEPTEMBER 27, 2021",
            duration: "3 MIN READ"
        }
    ]
};
export const saleOfNafedObj = {
    navSeq: agriInsights[3].name,
    cardsList: [
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-1.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-9-december-2020/",
            title: "Nafed PSS Stock : 9 December 2020",
            date: "DECEMBER 10,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-2.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-5-december-2020/",
            title: "Nafed PSS Stock : 5 December 2020",
            date: "DECEMBER 07,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-3.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-1-december-2020/",
            title: "Nafed PSS Stock : 1 December 2020",
            date: "DECEMBER 02,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-4.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-28-november-2020/",
            title: "Nafed PSS Stock : 28 November 2020",
            date: "NOVEMBER 29,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-5.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-26-november-2020/",
            title: "Nafed PSS Stock : 26 November 2020",
            date: "NOVEMBER 27,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-6.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-25-november-2020/",
            title: "Nafed PSS Stock : 25 November 2020",
            date: "NOVEMBER 26,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-7.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-24-november-2020/",
            title: "Nafed PSS Stock : 24 November 2020",
            date: "NOVEMBER 25,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-8.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-18-november-2020/",
            title: "Nafed PSS Stock : 18 November 2020",
            date: "NOVEMBER 19,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-9.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-17-november-2020/",
            title: "Nafed PSS Stock : 17 November 2020",
            date: "NOVEMBER 18,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-10.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-16-november-2020/",
            title: "Nafed PSS Stock : 16 November 2020",
            date: "NOVEMBER 17,2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-11.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-13-november-2020/",
            title: "Nafed PSS Stock : 13 November 2020",
            date: "NOVEMBER 16, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-12.jpg",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-12-november-2020/",
            title: "Nafed PSS Stock : 12 November 2020",
            date: "NOVEMBER 13, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-13.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-9-november-2020/",
            title: "Nafed PSS Stock : 9 November 2020",
            date: "NOVEMBER 10, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-14.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-7-november-2020/",
            title: "Nafed PSS Stock : 7 November 2020",
            date: "NOVEMBER 8, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-15.jpg",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-3-november-2020/",
            title: "Nafed PSS Stock : 3 November 2020",
            date: "NOVEMBER 4, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-16.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-2-november-2020/",
            title: "Nafed PSS Stock : 2 November 2020",
            date: "NOVEMBER 3, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-17.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-26-october-2020-2/",
            title: "Nafed PSS Stock : 26 October 2020",
            date: "NOVEMBER 2, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-18.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-30-october-2020/",
            title: "Nafed PSS Stock : 30 October 2020",
            date: "OCTOBER 31, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-19.png",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-29-october-2020/",
            title: "Nafed PSS Stock : 29 October 2020",
            date: "OCTOBER 30, 2020",
            duration: "1 MIN READ"
        },
        {
            imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/agriInsights/SaleOfNafed-20.jpg",
            imgAlt: "Nafed PSS Stock",
            href: "https://blog.agribazaar.com/nafed-pss-stock-28-october-2020/",
            title: "Nafed PSS Stock : 28 October 2020",
            date: "OCTOBER 29, 2020",
            duration: "1 MIN READ"
        }
    ]
};
