const appConfig = {
  environment: "preprod",
  domain: {
    PRODUCTION: {
      agribazaar: "https://api.agribazaar.com",
      blog: "https://blog.agribazaar.com",
      noderefresh: "https://api2.agribazaar.com",
    },
    UAT: {
      agribazaar: "http://apiuat.agribazaar.com",
      blog: "http://bloguat.agribazaar.com",
      noderefresh: "http://api2uat.agribazaar.com",
    },
    preprod: {
      agribazaar: "https://api-preprod.agribazaar.com",
      blog: "https://plogs-preprod.agribazaar.com",
      noderefresh: "https://apisolr-preprod.agribazaar.com",
    },
  },
  route: {
    PRODUCTION: "https://trade.agribazaar.com",
    UAT: "http://tradeuat.agribazaar.com",
    preprod: "https://trade-preprod.agribazaar.com",
  },
};

export default appConfig;
